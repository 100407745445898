import React, { FunctionComponent, useEffect } from 'react'
import styled from '@emotion/styled'

const UtterancesWrapper = styled.div`
    @media (max-width: 768px) {
        padding: 0 20px;
    }
`

type UtterancesOptions = {
    [name: string]: any
}

type UtterancesProps = UtterancesOptions
const Utterances: FunctionComponent<UtterancesProps> = ({
    repo,
}: UtterancesProps) => {
    const rootElm = React.createRef()
    useEffect(() => {
        const utterances = document.createElement('script')
        utterances.src = `https://utteranc.es/client.js`
        utterances.setAttribute(`repo`, repo)
        utterances.setAttribute(`branch`, 'main')
        utterances.setAttribute(`label`, 'comment')
        utterances.setAttribute(`issue-term`, `pathname`)
        utterances.setAttribute(`theme`, `github-light`)
        utterances.setAttribute(`crossorigin`, `anoymous`)
        utterances.async = true
        rootElm.current.appendChild(utterances)
    }, [])
    return <UtterancesWrapper ref={rootElm} />
}

export default Utterances
