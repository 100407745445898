import React, { ReactNode, FunctionComponent } from 'react'
import styled from '@emotion/styled/macro'
import tw from 'twin.macro'

type CardProps = {
    children: ReactNode
}
const CardContainer = styled.div`
    ${tw`container justify-center flex flex-col md:flex-row rounded-lg shadow-lg bg-white`}
`
const CardContent = styled.div`
    ${tw`p-6 flex flex-col justify-start`}
`
const Card: FunctionComponent<CardProps> = ({ children }: CardProps) => (
    <CardContainer>
        <CardContent>{children}</CardContent>
    </CardContainer>
)

export default Card
