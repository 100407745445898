import React, { FunctionComponent } from 'react'
import styled from '@emotion/styled/macro'
import { IGatsbyImageData } from 'gatsby-plugin-image'
import Metadata from 'components/Common/Metadata'
import tw from 'twin.macro'
export type PostHeaderInfoProps = {
    title: string
    timeToRead: number
    date: string
    categories: string[]
    thumbnail: IGatsbyImageData
}

const PostMetadata = styled(Metadata)`
    ${tw`flex object-left w-full`}
`

const PostHeaderInfo: FunctionComponent<PostHeaderInfoProps> = (
    props: PostHeaderInfoProps,
) => {
    const { title, timeToRead, date, categories } = props
    return (
        <PostMetadata
            title={title}
            timeToRead={timeToRead}
            date={date}
            categories={categories}
        />
    )
}

export default PostHeaderInfo
