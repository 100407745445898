import React, { FunctionComponent } from 'react'
import { Link } from 'gatsby'
import styled from '@emotion/styled/macro'
import Card from 'components/Common/Card'
import tw from 'twin.macro'
const PostNavigation = styled.div`
    ${tw`container flex justify-between`}
`
const PostNavigationItem = styled.div`
    ${tw`m-0`}
`
const PostNavigationLink = styled(Link)`
    ${tw`pl-2 pt-4 pr-2 pb-4 rounded-md opacity-80`}
`
const PostNavigationSubTitle = styled.div`
    ${tw`text-sm`}
`
const PostNavigationTitle = styled.h5`
    ${tw`text-lg text-gray-900 font-bold`}
`
const PostNavigationPreviousContent = styled(Card)`
    ${tw``}
    border-image: linear-gradient(180deg, #7f7fd5, #86a8e7, #91eac9);
    border-image-slice: 1;
`
const PostNavigationNextContent = styled(Card)`
    ${tw``}
    border-image: linear-gradient(360deg, #7f7fd5, #86a8e7, #91eac9);
    border-image-slice: 1;
`
type PostNavigatorProps = {
    previous: {
        fields: {
            slug: string
        }
        frontmatter: { title: string }
    }
    next: {
        fields: { slug: string }
        frontmatter: { title: string }
    }
}
export const PostNavigator: FunctionComponent<PostNavigatorProps> = ({
    previous,
    next,
}: PostNavigatorProps) => {
    return (
        <PostNavigation>
            {previous && (
                <PostNavigationItem>
                    <PostNavigationLink
                        to={previous?.fields.slug ?? '/'}
                        rel="이전포스트"
                    >
                        <PostNavigationPreviousContent>
                            <PostNavigationSubTitle>
                                이전 포스트
                            </PostNavigationSubTitle>
                            <PostNavigationTitle>
                                {previous?.frontmatter.title ?? '포스트 없음'}
                            </PostNavigationTitle>
                        </PostNavigationPreviousContent>
                    </PostNavigationLink>
                </PostNavigationItem>
            )}
            {next && (
                <PostNavigationItem>
                    <PostNavigationLink
                        to={next?.fields.slug ?? '/'}
                        rel="next"
                    >
                        <PostNavigationNextContent>
                            <PostNavigationSubTitle>
                                다음 포스트
                            </PostNavigationSubTitle>
                            <PostNavigationTitle>
                                {next?.frontmatter.title ?? '포스트 없음'}
                            </PostNavigationTitle>
                        </PostNavigationNextContent>
                    </PostNavigationLink>
                </PostNavigationItem>
            )}
        </PostNavigation>
    )
}

export default PostNavigator
