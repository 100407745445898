import React, { FunctionComponent } from 'react'
import { graphql } from 'gatsby'
import { PostPageItemType, PostNodeType } from 'types/PostItem.types'
import Layout, { Content } from 'components/Common/Layout'
import Header from 'components/Common/Header'
import PostHeaderInfo from 'components/Post/PostHeaderInfo'
import SEO from 'components/Common/SEO'

import PostContent from 'components/Post/PostContent'
import PostNavigator from 'components/Post/PostNavigator'
import TableOfContent from 'components/Post/TableOfContent'
import Utterances from 'components/Post/Utterances'
import { useSiteMetadata } from 'hooks/useSiteMetadata'
type PostTemplateProps = {
    path
    pageContext: PostListItemType
}

const PostTemplate: FunctionComponent<PostTemplateProps> = React.memo(
    ({ path, pageContext: { previous, node, next } }: PostTemplateProps) => {
        const {
            html,
            timeToRead,
            frontmatter: { title, excerpt, date, categories, thumbnail },
            tableOfContents,
        } = node
        const siteMetadata = useSiteMetadata()
        console.log(siteMetadata)
        return (
            <Layout
                siteMetadata={siteMetadata}
                tableOfContents={tableOfContents}
            >
                <PostHeaderInfo
                    title={title}
                    date={date}
                    timeToRead={timeToRead}
                    categories={categories}
                />
                <PostContent html={html} />
                <PostNavigator previous={previous} next={next} />
                <Utterances repo={siteMetadata.comment.utterances} />
            </Layout>
        )
    },
)
export const Head = SEO
export default PostTemplate
